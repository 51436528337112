import { Component, Input, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-successalert',
  templateUrl: './successalert.component.html',
  styleUrls: ['./successalert.component.scss'],
})
export class SuccessalertComponent implements OnInit {
  
  @Input() key1:string
  success1:boolean;
  success2:boolean;
  constructor(public popovercontroller:PopoverController,public nav:NavController,) { }

  ngOnInit() {
    // // console.log(this.key1)
    if(this.key1 == undefined){
      // // console.log("work")
      this.success1=false;
      this.success2=true;
    }
    else if(this.key1 != undefined){
      this.success1=true;
      this.success2=false;
    }
  }



  Alertdismiss(){
    // this.path=(sessionStorage.getItem('path'));
    // // // console.log(this.path);
    this.popovercontroller.dismiss();
    // if(this.path!=null){
    //   this.nav.navigateBack(this.path);
    //   sessionStorage.removeItem('path');
    // }

  }



}
